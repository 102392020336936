import request from "./request.js"
export function agentstatistics(params) {
    return request({
				url:"agentstatistics/grid.do?",
        method: 'post',
        data: params
    })
		
}

export function agentranking(params) {
    return request({
				url:"agentranking/grid.do?",
        method: 'post',
        data: params
    })
}

export function OrderList(params){
	return request({
		url:"/statistics/mealsale/list.do?",
		method:"post",
		data:params
	})
}

export function list4main(params){
	return request({
		url:"/statistics/adminstatis/list4main.do?",
		method:"post",
		data:params
	})
}


export function list4order(params){
	return request({
		url:"/statistics/adminstatis/list4order.do?",
		method:"post",
		data:params
	})
}

export function agentTrankList(params){
	return request({
		url:"/agentranking/grid.do?",
		method:"post",
		data:params
	})
}

export function list4pool(params){
	return request({
		url:"/statistics/adminstatis/list4pool.do?",
		method:"post",
		data:params
	})
}
export function statisGps(params){
	return request({
		url:"/statistics/adminstatis/statisGps.do?",
		method:"post",
		data:params
	})
}

export function carddayflow(params){
	return request({
		url:"/carddayflow/grid.do?",
		method:"post",
		data:params
	})
}

export function accountList(params){
	return request({
		url:"/system/cardOperator/getAccountListByAgent.do?",
		method:"post",
		data:params
	})
}

export function carddayflowExport(params){
	return request({
		url:"/carddayflow/exportExcel.do?",
		method:"post",
		data:params
	})
}

export function getStaticsAdmin(params){
	return request({
		url:"staticsOrder/getStaticsAdmin.do?",
		method:"post",
		data:params
	})
}

export function staticsAdminExport(params){
	return request({
		url:"staticsOrder/staticsAdminExport.do?",
		method:"post",
		data:params
	})
}


export function bindWxList(params){
	return request({
		url:"/agent/bindWx/extra/list.do?",
		method:"post",
		data:params
	})
}

export function getWxExtraUrl(params) {
    return request({
       url:"/agent/bindWx/getUrl.do?",
        method: 'post',
        params: params
    })
}


export function delWxExtra(params) {
    return request({
       url:"/agent/bindWx/extra/delete.do?",
        method: 'post',
        params: params
    })
}

export function list4active(params) {
    return request({
       url:"/statistics/adminstatis/list4active.do?",
        method: 'post',
        params: params
    })
}








